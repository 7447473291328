import { XMarkIcon } from '@heroicons/react/20/solid'
import * as ModalPrimitive from '@radix-ui/react-dialog'
import { cva } from 'class-variance-authority'
import { forwardRef, ReactNode } from 'react'

import { cn } from '../utils'

const ModalRoot = ModalPrimitive.Root

const ModalTrigger = ModalPrimitive.Trigger

const ModalPortal = ModalPrimitive.Portal

const ModalClose = ModalPrimitive.Close

const modalOverlayStyles = cva(
  'fixed inset-0 z-50 grid bg-gray-900 bg-opacity-40',
  {
    variants: {
      size: {
        fullscreen: 'justify-content-stretch items-stretch',
        standard: 'place-items-center p-4 sm:p-6',
      },
    },
  },
)

const ModalOverlay = forwardRef<
  React.ElementRef<typeof ModalPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof ModalPrimitive.Overlay> & {
    size?: 'fullscreen' | 'standard'
  }
>(({ className, size = 'standard', ...props }, ref) => (
  <ModalPrimitive.Overlay
    ref={ref}
    className={cn(modalOverlayStyles({ size }), className)}
    {...props}
  />
))
ModalOverlay.displayName = ModalPrimitive.Overlay.displayName

const modalContentStyles = cva(
  'relative flex max-h-full w-full flex-col overflow-y-auto bg-white shadow-lg',
  {
    variants: {
      size: {
        fullscreen: '',
        standard: 'max-w-lg rounded-lg',
      },
    },
  },
)

const ModalContent = forwardRef<
  React.ElementRef<typeof ModalPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof ModalPrimitive.Content> & {
    size?: 'fullscreen' | 'standard'
  }
>(({ className, children, size = 'standard', ...props }, ref) => (
  <ModalPortal>
    <ModalOverlay size={size}>
      <ModalPrimitive.Content
        ref={ref}
        className={cn(modalContentStyles({ size }), className)}
        {...props}
      >
        {children}
      </ModalPrimitive.Content>
    </ModalOverlay>
  </ModalPortal>
))
ModalContent.displayName = ModalPrimitive.Content.displayName

const ModalHeader = ({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex items-center gap-4 border-b border-gray-200 p-6',
      className,
    )}
    {...props}
  >
    {children}
    <ModalPrimitive.Close className="outline-primary-700 ml-auto rounded-sm">
      <XMarkIcon className="h-6 w-6 text-gray-400" />
      <span className="sr-only">Close</span>
    </ModalPrimitive.Close>
  </div>
)
ModalHeader.displayName = 'ModalHeader'

const ModalBody = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('flex-1 p-6 text-gray-700', className)} {...props} />
)

const ModalFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex flex-col-reverse gap-1.5 rounded-b bg-gray-50 p-4 sm:flex-row sm:justify-end sm:p-6 md:flex-row md:items-center',
      className,
    )}
    {...props}
  />
)
ModalFooter.displayName = 'ModalFooter'

const ModalTitle = forwardRef<
  React.ElementRef<typeof ModalPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof ModalPrimitive.Title>
>(({ className, ...props }, ref) => (
  <ModalPrimitive.Title
    ref={ref}
    className={cn('font-bold leading-none tracking-tight', className)}
    {...props}
  />
))
ModalTitle.displayName = ModalPrimitive.Title.displayName

const ModalDescription = forwardRef<
  React.ElementRef<typeof ModalPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof ModalPrimitive.Description>
>(({ className, ...props }, ref) => (
  <ModalPrimitive.Description
    ref={ref}
    className={cn('text-gray-700', className)}
    {...props}
  />
))
ModalDescription.displayName = ModalPrimitive.Description.displayName

type ModalActionsProps = {
  children: ReactNode
  className?: string
}
function ModalActions({ children, className }: ModalActionsProps) {
  return (
    <div
      className={cn(
        'flex flex-col-reverse gap-2 sm:ml-auto sm:flex-row sm:justify-end',
        className,
      )}
    >
      {children}
    </div>
  )
}

export const Modal = Object.assign(ModalRoot, {
  Close: ModalClose,
  Content: ModalContent,
  Description: ModalDescription,
  Footer: ModalFooter,
  Header: ModalHeader,
  Body: ModalBody,
  Overlay: ModalOverlay,
  Portal: ModalPortal,
  Title: ModalTitle,
  Trigger: ModalTrigger,
  Actions: ModalActions,
})
