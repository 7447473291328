import { Button, Modal } from '@qogita/ui'
import clsx from 'clsx'
import Image from 'next/image'
import { useState } from 'react'

import freeShipping from '/public/shipping/freeshipping.webp'
import { useTrackEvent } from '#lib/report/tracking'
import { vercelImageLoader } from '#lib/url'

export const ShippingBanner = ({ className }: { className?: string }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { trackShippingBannerModalViewed } = useTrackEvent()

  return (
    <Modal open={isModalOpen} onOpenChange={setIsModalOpen}>
      <div
        className={clsx(
          'bg-primary-700 flex w-full justify-center py-1',
          className,
        )}
      >
        <div className="q-text-body-xs flex items-center gap-4 text-white">
          <span className="q-text-body-xs-bold">
            Shipping with protection included
          </span>
          <Modal.Trigger asChild>
            <Button
              onClick={() => {
                trackShippingBannerModalViewed()
              }}
              color="custom"
              size="inline"
              className="text-white"
            >
              Learn more
            </Button>
          </Modal.Trigger>
        </div>
      </div>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Shipping with protection included</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Image
            src={freeShipping}
            alt="graphic of lorry driving on road"
            loader={vercelImageLoader}
            className="mb-6"
          />
          <div>
            <p>
              We restructured our pricing model. This means that from now on:
            </p>
            <ul className="list-inside list-disc pl-2.5 marker:text-sm">
              <li>You won’t need to pay for insurance separately</li>
              <li>Shipping with protection is now included in unit prices</li>
              <li>It is easier to calculate product margins</li>
            </ul>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  )
}
